<template>
  <v-layout wrap :class="isOwner ? 'owner' : 'pax'" v-if="values !== {}">
    <v-flex xs12 v-if="isOwner">
      {{ $t('booking.reservations.clientData', locale) }} :
    </v-flex>
    <v-flex xs11 v-else style="padding: 10px 0 0 10px">
      {{ $t('booking.reservations.pax', locale) }} {{ pos !== null ? pos + 1 : '' }}
    </v-flex>
    <v-flex xs1 v-if="onDeletePax">
      <v-spacer />
      <v-btn
        color="red"
        fab
        dark
        small
        top
        right
        text
        @click="onDeletePax(pos)"
      >
        <v-icon>mdi-delete-circle</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs12
      v-for="(field, index) of formFields"
      :key="index"
    >
      <v-text-field
        v-if="field.type === 'email'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-text-field
        :key="`text${pos}`"
        v-if="field.type === 'text'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-textarea
        v-if="field.type === 'textArea'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-autocomplete
        v-if="field.type === 'select'"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        v-model="values[field.key]"
        :items="field.items"
        dense
        hide-no-data
        hide-details
        color="primary"
        item-text="name"
        item-value="id"
        @change="handleChangeValues"
        outlined
        style="margin: 10px;"
      />
      {{field}}
      <v-autocomplete
        v-if="field.type === 'autocomplete'"
        v-model="values[field.key]"
        :items="field.items"
        filled
        chips
        color="blue-grey lighten-2"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        item-text="name"
        item-value="id"
        @change="handleChangeValues"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
          >
            <v-avatar left v-if="data.item.avatar">
              <v-img :src="data.item.avatar"></v-img>
            </v-avatar>
            {{ getTranslation(data.item.name, locale) }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar v-if="data.item.avatar">
              <img :src="data.item.avatar">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="getTranslation(data.item.name, locale)"></v-list-item-title>
              <v-list-item-subtitle v-html="getTranslation(data.item.group, locale)"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
export default {
  name: 'OwnerPaxFormItem',
  props: {
    formFields: {
      type: Array,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    pos: {
      type: Number,
      required: false,
    },
    onDeletePax: {
      type: Function,
      default: null
    },
    onChangeValues: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    values: {}
  }),
  computed: {        
    ...mapState('app', ['locale']),
  },
  watch: {
    formFields () {
      this.prepareValues()
    }
  },
  mounted () {
    alert('aaa')
    this.prepareValues()
  },
  methods: {
    prepareValues () {
      this.values = {}
      for (const field of this.formFields) {
        this.values[field.key] = field.defaultValue
      }
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleChangeValues () {
      this.onChangeValues(this.isOwner, this.values, this.pos)
    },
  }
}
</script>
<style scoped>
.owner {
}
.pax {
  background-color: #CCCCCC80;
  border-radius: 5px;
  margin-top: 10px;
}
.field {
  margin: 10px 10px;
}
</style>

